import { useUnit } from 'effector-react';

import { ContactForm } from '@/shared/components/contact-form/contact-form';
import {
  $isContactFormSentSuccessfully,
  contactFormDataSent,
  onContactFormClose,
} from '@/shared/components/contact-form/model';
import { EntryPoint } from '@/shared/components/contact-form/types';
import CheckMarkIcon from '@/shared/components/icons/checkmark-icon';
import { TextGradient } from '@/shared/components/text-gradient';
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from '@/shared/components/ui/dialog';

interface ContactModalProps {
  children: React.ReactNode;
  entryPoint: EntryPoint;
}

export const ContactModal = (props: ContactModalProps) => {
  const { children, entryPoint = 'DEFAULT' } = props;

  const { onContactFormClosed, isContactFormSentSuccessfully } = useUnit({
    onContactFormClosed: onContactFormClose,
    sendContactFormData: contactFormDataSent,
    isContactFormSentSuccessfully: $isContactFormSentSuccessfully,
  });

  return (
    <Dialog
      modal
      onOpenChange={() => {
        onContactFormClosed();
      }}
    >
      <DialogTrigger asChild>{children}</DialogTrigger>

      <DialogContent className="min-h-[400px] max-w-[90%] rounded-md sm:max-w-[425px]">
        {isContactFormSentSuccessfully && (
          <div className="flex h-full flex-col items-center justify-center pt-10">
            <CheckMarkIcon className="mb-4 h-10 w-10 shrink-0 rounded-full bg-brand-accent-light p-[2px] text-white dark:bg-brand-accent-normal" />

            <div className="text-center">
              <TextGradient text="Заявка отправлена!" className="mb-1 header-h1" />
              <p className="text-muted-foreground">Скоро мы вам напишем</p>
            </div>
          </div>
        )}

        {!isContactFormSentSuccessfully && (
          <>
            <DialogHeader>
              <DialogTitle>Оставить заявку</DialogTitle>
              <DialogDescription>Мы&nbsp;свяжемся с&nbsp;вами в&nbsp;мессенджере</DialogDescription>
            </DialogHeader>

            <ContactForm entryPoint={entryPoint} />
          </>
        )}
      </DialogContent>
    </Dialog>
  );
};
