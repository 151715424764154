import { createMutation } from '@farfetched/core';
import { createEffect } from 'effector';

import { ContactModalData } from '@/shared/components/contact-form/types';

import { httpClient } from '../api';
import { ContactFormResponse } from './types';

const URL = process.env.NODE_ENV === 'development' ? 'http://localhost:3000' : process.env.NEXT_PUBLIC_APP_URL;

export const apiEndpoint = httpClient.url(URL, true);

export const postContactFormFx = createEffect(async (formData: ContactModalData) => {
  const contactFormResponse = await apiEndpoint
    .url('/api/send-message')
    .headers({
      'Content-Type': 'application/json',
    })
    .body(
      JSON.stringify({
        formData,
      }),
    )
    .post()
    .json<ContactFormResponse>();

  return contactFormResponse;
});

export const postContactFormMutation = createMutation({
  handler: postContactFormFx,
});
