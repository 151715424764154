import { useState } from 'react';
import { useRouter } from 'next/router';
import { zodResolver } from '@hookform/resolvers/zod';
import { useUnit } from 'effector-react';
import { useForm } from 'react-hook-form';
import { z } from 'zod';

import { Button } from '@/shared/components/ui/button';
import { Form, FormControl, FormField, FormItem, FormLabel, FormMessage } from '@/shared/components/ui/form';
import { Input } from '@/shared/components/ui/input';
import { eventGA } from '@/shared/lib/analytics/google';
import { eventYM } from '@/shared/lib/analytics/yandex';
import { cn } from '@/shared/lib/cn';

import { TextShimmerAnimation } from '../animation/text-shimmer';
import ArrowIcon from '../icons/arrow-icon';
import AtIcon from '../icons/at-icon';
import { RadioGroup, RadioGroupItem } from '../ui/radio-group';
import { contactFormDataSent, onContactFormClose } from './model';
import { contactFormSchema } from './schema';
import { ContactModalData, EntryPoint } from './types';

interface ContactModalProps {
  className?: string;
  customButton?: React.ReactNode;
  entryPoint: EntryPoint;
}

export const ContactForm = (props: ContactModalProps) => {
  const { className, customButton, entryPoint = 'DEFAULT' } = props;

  const router = useRouter();

  const { sendContactFormData } = useUnit({
    onContactFormClosed: onContactFormClose,
    sendContactFormData: contactFormDataSent,
  });

  const ContactModal = useForm<z.infer<typeof contactFormSchema>>({
    resolver: zodResolver(contactFormSchema),
    mode: 'onBlur',

    defaultValues: {
      name: '',
      telegram: '',
    },
  });

  const { formState, handleSubmit, setValue, reset } = ContactModal;

  const [isOtherValueFieldVisible, setOtherValueFieldVisible] = useState(false);

  const onRadioChange = (value: any) => {
    setValue('source', value);
    setOtherValueFieldVisible(value === 'other');
    setValue('otherValue', '');
  };

  const onSubmit = async (values: z.infer<typeof contactFormSchema>) => {
    const { name, telegram, source, otherValue } = values;

    const contactModalData: ContactModalData = {
      sentFrom: entryPoint,
      url: router.asPath,

      name,
      telegram,
      source,
      otherValue,
    };

    await sendContactFormData(contactModalData);

    eventYM('lead');
    eventGA('lead');

    reset();
  };

  return (
    <Form {...ContactModal}>
      <form className={cn('max-w-5xl space-y-6', className)} onSubmit={handleSubmit(onSubmit)}>
        <FormField
          control={ContactModal.control}
          name="name"
          render={({ field }) => (
            <FormItem className="flex flex-col">
              <FormLabel>
                Имя<sup className="text-red-600">*</sup>
              </FormLabel>

              <FormControl>
                <Input {...field} placeholder="Ваше имя" />
              </FormControl>

              <FormMessage className="font-normal" />
            </FormItem>
          )}
        />

        <FormField
          control={ContactModal.control}
          name="telegram"
          render={({ field }) => (
            <FormItem className="flex flex-col">
              <FormLabel>
                Telegram<sup className="text-red-600">*</sup>
              </FormLabel>

              <FormControl>
                <Input {...field} placeholder="Ваш тег в телеграм" startAdornment={<AtIcon className="h-5 w-5" />} />
              </FormControl>

              <FormMessage className="font-normal" />
            </FormItem>
          )}
        />

        <FormField
          control={ContactModal.control}
          name="source"
          render={({ field }) => (
            <FormItem className="space-y-3">
              <FormLabel>
                Как вы о нас узнали<sup className="text-red-600">*</sup>
              </FormLabel>

              <FormControl>
                <RadioGroup
                  onValueChange={(value) => {
                    field.onChange(value);
                    onRadioChange(value);
                  }}
                  defaultValue={field.value}
                  className="flex flex-col space-y-1"
                >
                  <FormItem className="flex items-center space-x-3 space-y-0">
                    <FormControl>
                      <RadioGroupItem value="social" />
                    </FormControl>
                    <FormLabel className="font-normal">Реклама в соц. сетях</FormLabel>
                  </FormItem>

                  <FormItem className="flex items-center space-x-3 space-y-0">
                    <FormControl>
                      <RadioGroupItem value="search" />
                    </FormControl>
                    <FormLabel className="font-normal">Через поиск в интернете</FormLabel>
                  </FormItem>

                  <FormItem className="flex items-center space-x-3 space-y-0">
                    <FormControl>
                      <RadioGroupItem value="linkedin" />
                    </FormControl>
                    <FormLabel className="font-normal">LinkedIn</FormLabel>
                  </FormItem>

                  <FormItem className="flex items-center space-x-3 space-y-0">
                    <FormControl>
                      <RadioGroupItem value="other" onChange={() => onRadioChange} />
                    </FormControl>
                    <FormLabel className="font-normal">Другое</FormLabel>
                  </FormItem>
                </RadioGroup>
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />

        {isOtherValueFieldVisible && (
          <FormField
            control={ContactModal.control}
            name="otherValue"
            render={({ field }) => (
              <FormItem className="!mt-2 flex flex-col">
                <FormControl>
                  <Input {...field} placeholder="Расскажите, откуда вы о нас узнали" />
                </FormControl>

                <FormMessage className="font-normal" />
              </FormItem>
            )}
          />
        )}

        <div>
          <hr className="m-0 mb-4 h-px w-full border-none bg-gradient-to-r from-neutral-200/0 via-neutral-500/30 to-neutral-200/0" />

          {customButton && (
            <Button
              disabled={!formState.isValid || formState.isSubmitting}
              size="link"
              type="submit"
              className="group mx-auto mt-4  flex items-center justify-center rounded-[2rem] border border-brand-accent-light bg-background px-6 text-sm font-medium shadow-sm transition-colors hover:bg-accent hover:text-accent-foreground focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-ring disabled:pointer-events-none disabled:opacity-50"
              aria-label="Отправить контактную форму"
              onClick={() => handleSubmit(onSubmit)}
            >
              <TextShimmerAnimation className="flex items-center justify-center">
                Отправить
                <ArrowIcon className="ml-1 size-4 transition-all duration-300 ease-out group-hover:translate-x-1" />
              </TextShimmerAnimation>
            </Button>
          )}

          {!customButton && (
            <Button
              disabled={!formState.isValid || formState.isSubmitting}
              variant="cta"
              type="submit"
              className="mx-auto block tracking-wide"
              onClick={() => handleSubmit(onSubmit)}
              aria-label="Отправить контактную форму"
            >
              Отправить
            </Button>
          )}
        </div>
      </form>
    </Form>
  );
};
